import { select, call } from 'redux-saga/effects'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import usersSelectors from 'shared/selectors/usersSelectors'
import companySelectors from '../../../shared/selectors/companySelectors'
import createApiRequestSaga from 'shared/utils/sagas/createApiRequestSaga'
import Immutable from 'immutable'
import generateSustainabilitySurveyCertificate from 'shared/utils/generateSustainabilitySurveyCertificate'
import survey from 'shared/utils/api/survey'
import logger from 'shared/utils/logger'

export function* submitSustainabilitySurveySaga(action) {
  const {
    surveyId,
    totalPositiveResponses,
    totalCertificatesUploaded,
    totalDocumentsUploade,
    supplierParticipantIds
  } = Immutable.isImmutable(action.payload)
    ? action.payload.toJS()
    : action.payload

  const userId = yield select(sessionSelectors.getUserId)
  const company = yield select(companySelectors.getCompanyField, 'name')
  const surveyResponseId = surveyId.split('-')[3]

  let certTealbook = undefined
  const user = yield select(usersSelectors.getById, userId)

  const result = yield call(survey.submitSurvey, {
    surveyId,
    totalPositiveResponses,
    totalCertificatesUploaded,
    totalDocumentsUploade,
    supplierParticipantIds
  })

  if (!company) {
    yield select(state =>
      logger.addBreadcrumb(
        {
          category: 'error',
          message: 'Generate Survey Error: Company Name Not Present',
          data: {
            companyData: JSON.stringify(state.getIn(['supplier', 'company', 'data'])?.toJS())
          }
        }
      )
    )
  }
  certTealbook = yield generateSustainabilitySurveyCertificate({
    company,
    signBy: `${user.get('firstName')} ${user.get('lastName')}`,
    signOn: new Date(),
    email: user.get('email'),
    certId: surveyResponseId
  })
  
  const certFile = yield call(survey.uploadSustainabilitySurveyCert, {
    file: certTealbook,
    surveyId
  })
  result.sustainabilitySurveyCert = certFile

  return yield result
}

export default createApiRequestSaga(submitSustainabilitySurveySaga, {
  notifyOnError: true
})
