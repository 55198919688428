import { Record, fromJS, List, Set } from 'immutable'
import * as actionTypes from '../../actionTypes'
import { requestSuccess, requestFailure, LOGOUT } from 'shared/actionTypes'
import { LOGIN } from 'accounts/actionTypes'
import {
  SAVE_COMPANY_INFO,
  START_EDITING_COMPANY_HEADER,
  STOP_EDITING_COMPANY_HEADER,
  ADD_TAGS,
  REMOVE_TAGS,
  UPDATE_TAGS,
  ADD_CONTACT,
  EDIT_CONTACT,
  UPDATE_CONTACT,
  REMOVE_CONTACT,
  REMOVE_ALL_CONTACTS,
  ADD_CERTIFICATION,
  UPDATE_CERTIFICATION,
  REMOVE_CERTIFICATION,
  UPDATE_PARENT_ORG,
  REMOVE_BACKGROUND_PICTURE,
  UPLOAD_FILE,
  ADD_DOMAIN,
  GET_VERIFIED,
  OPEN_GET_VERIFIED_DIALOG,
  CLOSE_GET_VERIFIED_DIALOG,
  DELETE_LOGO,
  DELETE_PITCHDECK,
  ADD_SUGGESTED_TAGS,
  ADD_CLASSIFICATION_CODE,
  REMOVE_CLASSIFICATION_CODE,
  SET_RECORD_AS_FROZEN,
} from '../../../Company/actionTypes'
import { DELETE_DATA } from '../../../../shared/actionTypes'
import { CANCEL_SUBSCRIPTION } from '../../../shared/actionTypes'
import logger from 'shared/utils/logger'
import {
  ACCEPT_COMMUNITY,
  LEAVE_COMMUNITY,
} from '../../../Communities/actionTypes'
import { remapCertification } from 'shared/utils/data/parseSupplier'
import { ADD_SURVEY_COLLABORATE_CONTACT } from 'supplier/shared/actionTypes'
import { SUBMIT_SURVEY } from '../../../Survey/actionTypes'

const CompanyRecord = Record({
  data: fromJS({}),
  loading: true,
  loadError: undefined,
  editingHeader: false,
  editingContact: '',
  isPaymentDialogOpen: false,
  paymentProcessing: false,
  paymentCompleted: false,
  paymentError: undefined,
  verifyingOrgUnitId: '',
})

const companyReducer = (state = new CompanyRecord(), action) => {
  switch (action.type) {
    case actionTypes.LOAD_COMPANY:
      return state.set('loading', true)

    case requestSuccess(LOGIN):
      return action.payload.orgUnit
        ? state.merge({
            data: fromJS(action.payload.orgUnit),
          })
        : state

    case requestSuccess(actionTypes.LOAD_COMPANY):
      return state.merge({
        data: fromJS(action.payload),
        loading: false,
      })

    case requestFailure(actionTypes.LOAD_COMPANY):
      return state.merge({ loadError: action.payload, loading: false })

    case requestSuccess(SAVE_COMPANY_INFO):
      const newState = state
        .set('editingHeader', false)
        .set('data', fromJS(action.payload))
        .setIn(['data', 'expanded'], state.getIn(['data', 'expanded']))

      // update the domains if necessary - this happens when using tealbot
      return action.payload.domains
        ? newState.setIn(['data', 'domains'], fromJS(action.payload.domains))
        : newState

    case START_EDITING_COMPANY_HEADER:
      return state.set('editingHeader', true)

    case STOP_EDITING_COMPANY_HEADER:
      return state.set('editingHeader', false)

    case requestSuccess(UPDATE_TAGS):
      const removeTagResult = removeTags(state, action)
      return addTags(removeTagResult, action)

    case requestSuccess(ADD_TAGS):
      return addTags(state, action)

    case requestSuccess(REMOVE_TAGS):
      return removeTags(state, action)

    case requestSuccess(ADD_SUGGESTED_TAGS):
      return state
        .updateIn(['data', 'supplier', 'offerings'], (offerings) =>
          offerings.concat(List(action.payload.offerings))
        )
        .updateIn(['data', 'supplier', 'differentiators'], (differentiators) =>
          differentiators.concat(List(action.payload.differentiators))
        )

    case requestSuccess(ADD_SURVEY_COLLABORATE_CONTACT):
      return action.payload.isPublicSupplierContact
        ? state.updateIn(['data', 'supplier', 'contacts'], (contacts) => {
            if (
              contacts.some((c) => c.get('user') === action.payload.user.id)
            ) {
              return contacts
            } else {
              return contacts.push(
                fromJS({
                  user: action.payload.user.id,
                })
              )
            }
          })
        : state

    case requestSuccess(ADD_CONTACT):
      return state.updateIn(['data', 'supplier', 'contacts'], (contacts) => {
        // add it only if is not there
        if (contacts.some((c) => c.get('user') === action.payload.user.id)) {
          return contacts
        } else {
          return contacts.push(
            fromJS({
              user: action.payload.user.id,
              contactFor: action.payload.contactFor,
            })
          )
        }
      })

    case EDIT_CONTACT:
      return state.set('editingContact', action.payload)

    case requestSuccess(UPDATE_CONTACT):
      return state
        .set('editingContact', '')
        .updateIn(['data', 'supplier', 'contacts'], (contacts) => {
          if (action.payload.contactFor === undefined) {
            return contacts
          } else {
            const index = contacts?.findIndex(
              (c) => c.get('user') === action.payload.id
            )
            return index !== -1
              ? contacts.updateIn([index], (contact) =>
                  contact.set('contactFor', action.payload.contactFor)
                )
              : contacts
          }
        })

    case requestSuccess(DELETE_DATA):
      if (action.payload.entity !== 'users') {
        return state
      }

      return state.updateIn(['data', 'supplier', 'contacts'], (contacts) => {
        if (!contacts) {
          return contacts
        }

        const deleteIndex = contacts?.findIndex(
          (c) => c.get('user') === action.payload.recordId
        )
        return deleteIndex > -1 ? contacts.delete(deleteIndex) : contacts
      })
    case requestSuccess(REMOVE_CONTACT):
      return state.updateIn(['data', 'supplier', 'contacts'], (contacts) => {
        const deleteIndex = contacts?.findIndex(
          (c) => c.get('user') === action.payload
        )
        return contacts.delete(deleteIndex)
      })
    case requestSuccess(REMOVE_ALL_CONTACTS):
      return state.updateIn(['data', 'supplier', 'contacts'], (contacts) => {
        return contacts.clear()
      })
    case requestSuccess(UPDATE_CERTIFICATION):
    case requestSuccess(ADD_CERTIFICATION):
      return state.updateIn(
        ['data', 'supplier', 'certification'],
        (certifications) => {
          if (certifications) {
            const index = certifications.findIndex(
              (c) =>
                c.get('category') === action.payload.category &&
                c.get('subCategory') === action.payload.subCategory &&
                c.get('timeStamp') === action.payload.timeStamp
            )

            return index < 0
              ? certifications.push(fromJS(remapCertification(action.payload)))
              : certifications.set(
                  index,
                  fromJS(remapCertification(action.payload))
                )
          } else {
            return List([fromJS(action.payload)])
          }
        }
      )

    case requestSuccess(REMOVE_CERTIFICATION):
      return state.updateIn(
        ['data', 'supplier', 'certification'],
        (certifications) => {
          const index = certifications.findIndex(
            (c) =>
              c.get('category') === action.payload.category &&
              c.get('subCategory') === action.payload.subCategory &&
              c.get('timeStamp') === (action.payload.timeStamp || undefined)
          )
          return certifications.remove(index)
        }
      )

    case requestSuccess(UPDATE_PARENT_ORG):
      const parentOrgId = state
        .getIn(['data', 'expanded', 'Org'])
        .keySeq()
        .first()

      return state.mergeIn(
        ['data', 'expanded', 'Org', parentOrgId],
        fromJS(action.payload)
      )

    case requestSuccess(REMOVE_BACKGROUND_PICTURE):
      return state.setIn(['data', 'supplier', 'backgroundPicture'], null)

    case requestSuccess(DELETE_LOGO):
      return state.setIn(['data', 'supplier', 'logo'], undefined)

    case requestSuccess(DELETE_PITCHDECK):
      return state.setIn(['data', 'supplier', 'pitchDeckFile'], undefined)

    case requestSuccess(UPLOAD_FILE):
      return state.mergeIn(['data', 'supplier'], fromJS(action.payload))

    case requestSuccess(ADD_DOMAIN):
      const domains = state.getIn(['data', 'domains'])
      return action.payload
        ? state.setIn(['data', 'domains'], domains.push(action.payload))
        : state

    case actionTypes.SHOW_PAYMENT_DIALOG:
      return state.merge(
        fromJS({
          isPaymentDialogOpen: true,
          paymentProcessing: false,
          paymentCompleted: false,
          paymentError: undefined,
        })
      )

    case actionTypes.HIDE_PAYMENT_DIALOG:
      return state.set('isPaymentDialogOpen', false)

    case actionTypes.PROCESS_PAYMENT:
      return state.merge(
        fromJS({
          paymentProcessing: true,
          paymentCompleted: false,
          paymentError: undefined,
        })
      )

    case requestSuccess(actionTypes.PROCESS_PAYMENT):
      return state
        .merge(
          fromJS({
            paymentProcessing: false,
            paymentCompleted: true,
            paymentError: undefined,
          })
        )
        .setIn(['data', 'supplier', 'supplierType'], 'basic')
        .setIn(
          ['data', 'supplier', 'stripe', 'signupDate'],
          new Date().toISOString()
        )

    case requestFailure(actionTypes.PROCESS_PAYMENT):
      return state.merge(
        fromJS({
          paymentProcessing: false,
          paymentCompleted: false,
          paymentError: action.payload,
        })
      )

    case OPEN_GET_VERIFIED_DIALOG:
      return state.set('verifyingOrgUnitId', action.payload)

    case requestSuccess(GET_VERIFIED):
    case CLOSE_GET_VERIFIED_DIALOG:
      return state.set('verifyingOrgUnitId', '')

    case requestSuccess(CANCEL_SUBSCRIPTION):
      return state
        .setIn(['data', 'supplier', 'supplierType'], null)
        .setIn(['data', 'supplier', 'stripe'], null)

    case LOGOUT:
      return new CompanyRecord()

    case requestSuccess(ACCEPT_COMMUNITY):
      const { commId, supplierSearchable } = action.payload
      return supplierSearchable
        ? state.updateIn(
            ['data', 'supplier', 'searchableCommunities'],
            (searchableCommunities) =>
              searchableCommunities
                ? searchableCommunities.push(commId)
                : List([commId])
          )
        : state

    case requestSuccess(ADD_CLASSIFICATION_CODE):
      let classificationCodes = state.getIn([
        'data',
        'supplier',
        'classificationCodes',
      ])
      if (!classificationCodes) {
        classificationCodes = List()
      }
      return action.payload
        ? state.setIn(
            ['data', 'supplier', 'classificationCodes'],
            classificationCodes.push(action.payload)
          )
        : state

    case requestSuccess(REMOVE_CLASSIFICATION_CODE):
      return state.updateIn(
        ['data', 'supplier', 'classificationCodes'],
        (classificationCode) => {
          const deleteIndex = classificationCode.findIndex(
            (c) =>
              action.payload.schema &&
              c.get('schema').toLowerCase() ===
                action.payload.schema.toLowerCase() &&
              c.get('code') === action.payload.code
          )
          return classificationCode.delete(deleteIndex)
        }
      )

    case requestSuccess(LEAVE_COMMUNITY):
      const { communityId } = action.payload
      return (
        state
          .getIn(['data', 'supplier', 'searchableCommunities'])
          ?.filter((community) => community !== communityId) || state
      )
    case requestSuccess(SUBMIT_SURVEY):
      return state.setIn(
        ['data', 'supplier', 'sustainabilitySurveyCert'],
        fromJS(action.payload.surveyCert)
      )

    case requestSuccess(SET_RECORD_AS_FROZEN):
      return state.set('data', fromJS(action.payload))

    default:
      return state
  }
}

export default companyReducer

function addTags(state, action) {
  const { newTags, fieldName } = action.payload
  const tags = getTags(state, fieldName).toSet()

  logger.addBreadcrumb({
    category: 'action',
    message: action.type,
    data: {
      tags: tags && tags.toJS(),
      payloads: action.payload && JSON.stringify(action.payload),
    },
  })

  return setTags(state, fieldName, tags.union(Set(newTags)).toList())
}

function removeTags(state, action) {
  const { tagToRemove, fieldName } = action.payload
  const tags = getTags(state, fieldName)

  logger.addBreadcrumb({
    category: 'action',
    message: action.type,
    data: {
      tags: tags && tags.toJS(),
      payloads: action.payload && JSON.stringify(action.payload),
    },
  })

  return setTags(
    state,
    fieldName,
    tags.filter((tag) => {
      return Array.isArray(tagToRemove)
        ? !tagToRemove.map((t) => t.toLowerCase()).includes(tag.toLowerCase())
        : tag.toLowerCase() !== tagToRemove.toLowerCase()
    })
  )
}

function setTags(state, fieldName, tags) {
  return state.setIn(['data', 'supplier', fieldName], tags)
}

function getTags(state, fieldName) {
  return state.getIn(['data', 'supplier', fieldName]) || fromJS([])
}
