import { combineReducers, Reducer } from 'redux'
import * as actionTypes from './actionTypes'
import { requestSuccess, UPLOAD_TIER2_SPEND_FILE } from 'shared/actionTypes'
import { fromJS, List } from 'immutable'

export type AllocatedSpendState = {
  isLoading: boolean
  data: Array<{
    buyerOrgUnitId: string
    buyerName: string
    communityPlanMembershipId: string
    allocatedSpend: {
      [year: number]: {
        [quarter: number]: {
          overallAllocation: number
          stagingId: string
          diverseSupplierAllocations: Array<{
            id: string
            allocation: number
            spend: number
            name: string
            logoUrl?: string
          }>
          isDirFileIndirAgg: boolean
        }
      }
    }
    attestedBy?: string
  }>
}

const allocatedSpendDefaultState: AllocatedSpendState = {
  isLoading: false,
  data: [],
}

const allocatedSpend: Reducer<AllocatedSpendState> = (
  state = allocatedSpendDefaultState,
  action
) => {
  switch (action.type) {
    case actionTypes.LOAD_TIER2_ALLOCATED_SPEND:
      return {
        ...state,
        isLoading: true,
      }

    case requestSuccess(actionTypes.LOAD_TIER2_ALLOCATED_SPEND):
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }

    case requestSuccess(actionTypes.UPDATE_TIER2_ALLOCATED_SPEND):
      const { diverseSupplierAllocations = [], ...rest } = action.payload
      return {
        ...state,
        data: state.data.map((buyer) =>
          buyer.communityPlanMembershipId ===
          action.payload.communityPlanMembershipId
            ? {
                ...buyer,
                allocatedSpend: {
                  ...buyer.allocatedSpend,
                  [action.payload.year]: {
                    ...buyer.allocatedSpend[action.payload.year],
                    [action.payload.quarter]: {
                      ...rest,
                      // need to update diverseSupplierAllocations instead of replacing it
                      diverseSupplierAllocations: buyer.allocatedSpend[
                        action.payload.year
                      ][action.payload.quarter]
                        ? buyer.allocatedSpend[action.payload.year][
                            action.payload.quarter
                          ].diverseSupplierAllocations?.map((supplier) => {
                            const updateSupplier =
                              diverseSupplierAllocations.find(
                                (ds) => ds.id === supplier.id
                              )
                            return updateSupplier || supplier
                          })
                        : diverseSupplierAllocations,
                    },
                  },
                },
              }
            : buyer
        ),
      }

    default:
      return state
  }
}

type AllocatedSpendValidation = {
  updatedAllocationBuyers: Array<string>
  invalidSuppliers: Array<string>
  allocationByStagingId: {
    [stagingId: string]: {
      [supplierOrgUnitId: string]: {
        [buyerOrgUnitId: string]: [number, number] // saved allocation, current allocation
      }
    }
  }
}

const allocatedSpendValidationState: AllocatedSpendValidation = {
  updatedAllocationBuyers: [],
  invalidSuppliers: [],
  allocationByStagingId: {},
}

const allocatedSpendValidation = (
  state = fromJS(allocatedSpendValidationState),
  action
) => {
  let allocationByStagingId
  switch (action.type) {
    case actionTypes.UPDATE_ALLOCATION:
      const { stagingId, supplierOrgUnitId, buyerOrgUnitId, allocation } =
        action.payload
      const buyerValues = state.getIn([
        'allocationByStagingId',
        stagingId,
        supplierOrgUnitId,
        buyerOrgUnitId,
      ])
      const updatedBuyerValues = buyerValues
        ? buyerValues.set(1, allocation || 0)
        : fromJS([0, allocation || 0])
      return !!state.getIn(['allocationByStagingId', stagingId])
        ? state
            .setIn(
              [
                'allocationByStagingId',
                stagingId,
                supplierOrgUnitId,
                buyerOrgUnitId,
              ],
              updatedBuyerValues
            )
            .updateIn(['updatedAllocationBuyers'], (updatedAllocationBuyers) =>
              updatedAllocationBuyers.push(buyerOrgUnitId).toSet().toList()
            )
        : state

    case requestSuccess(actionTypes.LOAD_TIER2_ALLOCATED_SPEND):
      allocationByStagingId = action.payload?.reduce(
        (validationMap, membership) => {
          const allocatedSpend = membership.allocatedSpend
          // loads group by years then by quarters
          // flatten the load
          const extractQuarters = Object.values(allocatedSpend)
          const extractLoads: any = extractQuarters.reduce(
            (result: any[], quarter: object) => {
              result = [...result, ...Object.values(quarter)]
              return result
            },
            []
          )
          let buyerOrgUnitId = membership.buyerOrgUnitId
          extractLoads
            .filter((l) => !!l.attestedBy)
            .forEach((spendLoad: any) => {
              if (typeof spendLoad.stagingId === 'string') {
                if (!validationMap[spendLoad.stagingId]) {
                  // allocation per supplier
                  let stagingAllocation =
                    spendLoad.diverseSupplierAllocations.reduce(
                      (supplierMap, supplier) => {
                        supplierMap[supplier.id] = {
                          [buyerOrgUnitId]: [
                            supplier.allocation || 0,
                            supplier.allocation || 0,
                          ],
                        }
                        return supplierMap
                      },
                      {}
                    )
                  validationMap[spendLoad.stagingId] = stagingAllocation
                } else {
                  // merge
                  spendLoad.diverseSupplierAllocations.forEach((supplier) => {
                    if (validationMap[spendLoad.stagingId][supplier.id]) {
                      validationMap[spendLoad.stagingId][supplier.id][
                        buyerOrgUnitId
                      ] = [supplier.allocation || 0, supplier.allocation || 0]
                    } else {
                      validationMap[spendLoad.stagingId][supplier.id] = {
                        [buyerOrgUnitId]: [
                          supplier.allocation || 0,
                          supplier.allocation || 0,
                        ],
                      }
                    }
                  }, {})
                }
                if (!validationMap[spendLoad.stagingId]['overallAllocation']) {
                  validationMap[spendLoad.stagingId]['overallAllocation'] = {
                    [buyerOrgUnitId]: [
                      spendLoad.overallAllocation || 0,
                      spendLoad.overallAllocation || 0,
                    ],
                  }
                } else {
                  validationMap[spendLoad.stagingId]['overallAllocation'][
                    buyerOrgUnitId
                  ] = [
                    spendLoad.overallAllocation || 0,
                    spendLoad.overallAllocation || 0,
                  ]
                }
              } else {
                // file and aggregate
                spendLoad.stagingId?.forEach((stagingId) => {
                  if (!validationMap[stagingId]) {
                    const stagingAllocation =
                      spendLoad.diverseSupplierAllocations.reduce(
                        (supplierMap, supplier) => {
                          supplierMap[supplier.id] = {
                            [buyerOrgUnitId]: [
                              supplier.allocation || 0,
                              supplier.allocation || 0,
                            ],
                          }
                          return supplierMap
                        },
                        {}
                      )
                    validationMap[stagingId] = stagingAllocation
                  } else {
                    // merge
                    spendLoad.diverseSupplierAllocations?.forEach(
                      (supplier) => {
                        if (validationMap[stagingId][supplier.id]) {
                          validationMap[stagingId][supplier.id][
                            buyerOrgUnitId
                          ] = [
                            supplier.allocation || 0,
                            supplier.allocation || 0,
                          ]
                        } else {
                          validationMap[stagingId][supplier.id] = {
                            [buyerOrgUnitId]: [
                              supplier.allocation || 0,
                              supplier.allocation || 0,
                            ],
                          }
                        }
                      },
                      {}
                    )
                  }
                })
                if (validationMap[spendLoad.aggregateLoad]) {
                  if (
                    !validationMap[spendLoad.aggregateLoad]['overallAllocation']
                  ) {
                    validationMap[spendLoad.aggregateLoad][
                      'overallAllocation'
                    ] = {
                      [buyerOrgUnitId]: [
                        spendLoad.overallAllocation || 0,
                        spendLoad.overallAllocation || 0,
                      ],
                    }
                  } else {
                    validationMap[spendLoad.aggregateLoad]['overallAllocation'][
                      buyerOrgUnitId
                    ] = [
                      spendLoad.overallAllocation || 0,
                      spendLoad.overallAllocation || 0,
                    ]
                  }
                }
              }
            })
          return validationMap
        },
        {}
      )
      return state.set('allocationByStagingId', fromJS(allocationByStagingId))

    case requestSuccess(actionTypes.UPDATE_TIER2_ALLOCATED_SPEND):
      return state.updateIn(
        ['allocationByStagingId'],
        (allocationByStagingId) => {
          let updatedAllocationByStagingId = allocationByStagingId
          if (typeof action.payload.stagingId === 'string') {
            action.payload.diverseSupplierAllocations.forEach((supplier) => {
              updatedAllocationByStagingId = updatedAllocationByStagingId.setIn(
                [action.payload.stagingId, supplier.id, action.payload.buyerId],
                fromJS([supplier.allocation, supplier.allocation])
              )
            })
            updatedAllocationByStagingId = updatedAllocationByStagingId.setIn(
              [
                action.payload.stagingId,
                'overallAllocation',
                action.payload.buyerId,
              ],
              fromJS([
                action.payload.overallAllocation,
                action.payload.overallAllocation,
              ])
            )
          } else {
            action.payload.stagingId.forEach((stagingId) => {
              action.payload.diverseSupplierAllocations.forEach((supplier) => {
                updatedAllocationByStagingId =
                  updatedAllocationByStagingId.setIn(
                    [stagingId, supplier.id, action.payload.buyerId],
                    fromJS([supplier.allocation, supplier.allocation])
                  )
              })
              updatedAllocationByStagingId = updatedAllocationByStagingId.setIn(
                [stagingId, 'overallAllocation', action.payload.buyerId],
                fromJS([
                  action.payload.overallAllocation,
                  action.payload.overallAllocation,
                ])
              )
            })
          }
          return updatedAllocationByStagingId
        }
      )

    default:
      return state
  }
}

export type Tier2CommunityState = {
  isLoadingCommunities: boolean
  tier2CommMemberships: any
}
const tier2CommunityDefaultState: Tier2CommunityState = {
  isLoadingCommunities: false,
  tier2CommMemberships: [],
}

const tier2Communities = (
  state = fromJS(tier2CommunityDefaultState),
  action
) => {
  switch (action.type) {
    case actionTypes.GET_TIER2_COMMUNITIES:
      return state.set('isLoadingCommunities', true)

    case requestSuccess(actionTypes.GET_TIER2_COMMUNITIES):
      return state
        .set('isLoadingCommunities', false)
        .set('tier2CommMemberships', fromJS(action.payload))

    default:
      return state
  }
}

export type SummaryRowType = {
  loadId: string
  year?: number
  quarter?: number
  dates: string[] // ['2019-12-1', '2020-1-1', '2020-2-1']
  domain: string // 'missing.ink',
  category: string //'diversity',
  subCategories: Array<{
    subCategory: string
    subType?: string
    spend: number
  }>
  stagingId?: string
}

export type DiverseSummaryType = {
  isLoading: boolean
  data: Array<SummaryRowType>
}

const diverseSummaryDefaultState: DiverseSummaryType = {
  isLoading: false,
  data: [],
}

const diverseSummary = (state = diverseSummaryDefaultState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_TIER2_DIVERSE_SUMMARY:
      return {
        ...state,
        isLoading: true,
      }

    case requestSuccess(actionTypes.LOAD_TIER2_DIVERSE_SUMMARY):
      return {
        isLoading: false,
        data: action.payload,
      }

    case requestSuccess(actionTypes.POST_TIER2_DIVERSE_SUMMARY):
      const updateData = [...state.data, action.payload]
      return {
        ...state,
        data: updateData,
      }

    case requestSuccess(actionTypes.REMOVE_TIER2_DIVERSE_SUMMARY):
      const index = state.data.findIndex((d) => d.loadId === action.payload)
      state.data.splice(index, 1)
      return {
        ...state,
        data: [...state.data],
      }
    default:
      return state
  }
}

const fileLoadErrors = (state = List([]), action) => {
  switch (action.type) {
    case UPLOAD_TIER2_SPEND_FILE:
      return fromJS([])
    case requestSuccess(UPLOAD_TIER2_SPEND_FILE):
      return fromJS(action.payload)
    default:
      return state
  }
}

export default combineReducers({
  allocatedSpend,
  allocatedSpendValidation,
  tier2Communities,
  diverseSummary,
  fileLoadErrors,
})
