import { fromJS, RecordOf, List, Map } from 'immutable'
import * as actionTypes from './actionTypes'
import {
  LOAD_INTERNAL_STATS,
  LOAD_INTERNAL_STAT_KEYS,
  requestFailure,
} from 'shared/actionTypes'
import {
  LOAD_COMMUNITY_INSIGHT,
  INVITE_TO_COMMUNITIES,
} from '../../Communities/actionTypes'
import { requestSuccess } from '../../../shared/actionTypes'
import { Moment } from 'moment'
import DiversityCategory from 'shared/models/DiversityCategory'

type ActiveUser = RecordOf<{
  id: string
  firstName: string
  lastName: string
  numberOfFollows: number | null
  numberOfProfileViews: number | null
  numberOfVets: number | null
  numberOfContacts: number | null
  numberOfConnections: number | null
  numberOfRatings: number | null
  numberOfSearches: number | null
  numberOfTags: number | null
  profilePictureUrl: string
}>

type ActiveSupplier = RecordOf<{
  id: string
  name: string
  profilePictureUrl: string
  views: number | null
  viewsThisMonth: number | null
  viewsLastMonth: number | null
}>

type CompanyTag = RecordOf<{
  value: string
  count: number
}>

type SearchTerm = RecordOf<{
  value: string
  count: number
}>

type SearchGraph = RecordOf<{
  monthYear: string
  numberOfSearches: number
}>

type CompanyStats = RecordOf<{
  numberOfUsers: number
  numberOfVets: number
  numberOfLogins: number
  numberOfConnectedSuppliers: number
  numberOfExistingSuppliers: number
  numberOfPreferredSuppliers: number
  numberOfSearches: number
}>

type Consolidation = RecordOf<{
  profilesEmails: number | null
  profilesTags: number | null
  profilesConsolidated: number | null
  buyerInternalIds: number | null
  internalIdsMatched: number | null
  internalIdsUnMatched: number | null
  internalIdsUnMatchedList: List<string>
  buyerEmails: number | null
  buyerTags: number | null
  buyerAddresses: number | null
  profileAddresses: number | null
  profilesMultipleIds: number | null
  profileWarnings: Map<
    string,
    List<
      RecordOf<{
        orgUnitId: string
        domains: List<string>
        name: string
        internalIds: List<string>
      }>
    >
  >
  completeness: RecordOf<{
    addresses: number
    contacts: number
    tags: number
    naics: number
    description: number
    references: number
  }>
  top100: List<
    RecordOf<{
      id: string
      name: string
      domains: List<string>
      matchedInternalIds: List<string>
    }>
  >
}>

type Spend = RecordOf<{
  years: List<string>
  minSpendDate: Moment | undefined
  maxSpendDate: Moment | undefined
  manualTotalSpend?: number
  totalSpend: number
  selectedSpend: number
  totalDiverseSpend: number
  totalCountrySpend: number
  useSpendGroup: boolean
  onlyPreferred: boolean
  onlyDiverse: boolean
  categories: List<string>
  countries: List<string>
  spendgroups: List<string>
  overview: Map<
    string,
    Map<
      string,
      Map<
        string,
        RecordOf<{
          numSuppliers: number
          preferredAmount: number
          diverseAmount: number
          totalAmount: number
          percentage: number
          category?: string
          diversePercentage: number
          preferredPercentage: number
        }>
      >
    >
  >
  preview: Map<
    string,
    Map<
      string,
      List<
        RecordOf<{
          name: string
          diverse: boolean
          OrgUnitId: string
          diversityProgram: boolean | null
          preferred: boolean
          totalAmount: number
          category: string
          percentage: number
        }>
      >
    >
  >
}>

type Supplier = {
  domains: List<string>
  name: string
  id: string
}

export type Tier2Item = {
  supplier: RecordOf<Supplier>
  tier2Type: DiversityCategory
  subCategories: List<DiversityCategory>
  spend: number
  numSuppliers: number
  lastUpdate: Date
}

type Tier2 = RecordOf<{
  minYear?: number
  minQuarter?: number
  maxYear?: number
  maxQuarter?: number
  isLoadingRange: boolean
  certAgencies: List<string>
  selectedCategories: List<string>
  isLoading: boolean
  data: Map<string, List<RecordOf<Tier2Item>>>
  totalSpend: Map<string, number>
  supplierList: List<
    RecordOf<{
      supplier: RecordOf<{ name: string; domains: List<string>; id: string }>
      status: string
      statusDate: string
      tier2: List<RecordOf<{ year: string; quarter: string }>>
    }>
  >
  tier2Community: List<RecordOf<{ id: string }>>
  search: RecordOf<{
    queryString: string
    numberOfSuppliersToShow: number
    resultsCount: number
    results: List<
      RecordOf<{
        id: string
        logo: string
        name: string
        domains: List<string>
      }>
    >
  }>
  uniqueTotalTier2Spend: number
}>

export type InternalStats = {
  internalStatsKeys: Array<string>
  internalStatsQueries: any
}

export type Snapshot = {
  id: string
  name: string
  created: {
    user: string
    date: string
  }
  disqualifiedSpend: number
  potentialSpend: number
  qualifiedSpend: number
  totalDiverseSpend: number
  totalSpend: number
  overview: {
    [subCategory: string]: {
      disqualifiedAmount: number
      disqualifiedCount: number
      potentialAmount: number
      potentialCount: number
      qualifiedAmount: number
      qualifiedCount: number
      qualifiedPercentage: string
      type: string
    }
  }
  reportingPeriod: string[]
  rules: {
    baseRules_certAgencies: string[]
    baseRules_countries: string[]
    baseRules_subCategories: string[]
    excludeRules_myTeam: 'ignore' | 'rejected'
    includeRules_attestation_certAgencies: string[]
    includeRules_attestation_notVerifiedByTealbook: boolean
    includeRules_attestation_selfCertified: boolean
    includeRules_completeness_attachmentAgencies: string[]
    includeRules_completeness_attachmentAgenciesSelected: boolean
    includeRules_myTeam: 'ignore' | 'includeExpired' | 'anyAgencies'
    includeRules_tealbook: 'ignore' | 'includeValid' | 'anyAgencies'
  }
  filters: {
    countries: string[]
    categories: string[]
    businessUnits: string[]
  }
  summary: {
    disqualifiedSpend: number
    disqualifiedSupplierCount: number
    potentialSpend: number
    potentialSupplierCount: number
    qualifiedSpend: number
    qualifiedSupplierCount: number
    totalDiverseSpend: number
    totalDiverseSuppliersCount: number
  }
}

export type SnapshotLineItem = {
  id: string
  orgUnitId: string
  domain: string
  name: string
  totalAmount: number
  qualified: string
  [key: string]: any
}

type SaveReports = {
  isLoading: boolean
  isSaving: boolean
  isDeleting: {
    [id: string]: boolean
  }
  byId: {
    [id: string]: RecordOf<Snapshot>
  }
  SnapshotLineItems: {
    [snapshotId: string]: RecordOf<SnapshotLineItem>[]
  }
}

export type SustainabilityReportOverview = {
  numExistingSuppliers: number
  numCompletedSurveys: number
  numInvitations: number
  numSurveyViewedInvitations: number
  numCompletedInvitations: number
  numCreatedInvitations: number
  numInProgressInvitations: number
  numCerts: number
  numPositiveActions: number
}

type SustainabilityReport = {
  isLoading: boolean
  isLoadingDetail: boolean
  isLoadingQuestionStats: boolean
  openQuestionsStatsDialog: boolean
  overview: RecordOf<SustainabilityReportOverview>
  completedSuppliers: List<any>
  completedSuppliersByQuestion: List<any>
  details: {
    [pageId: string]: any
  }
  showQuestions: {
    [questionId: string]: boolean
  }
}

export type InsightsState = {
  isLoadingDetail: boolean
  isLoadingSpend: boolean
  isLoadingActiveUsers: boolean
  isLoading: boolean
  isLoadingConsolidation: boolean
  isLoadingInternalStats: boolean
  isLoadingInternalStatKeys: boolean
  isHistoricalSpendData: boolean
  companyStats: List<CompanyStats>
  searchGraph: List<SearchGraph>
  commonSearchTerms: List<SearchTerm>
  companyTags: List<CompanyTag>
  mostActiveSuppliers: List<ActiveSupplier>
  mostActiveUsers: List<ActiveUser>
  consolidation?: Consolidation
  spend?: Spend
  tier2?: Tier2
  saveReports: SaveReports
  isLoadingSupplierList: boolean
  internalStats: InternalStats
  sustainabilityReport: SustainabilityReport
}

const defaultState = fromJS({
  isLoading: false,
  isLoadingActiveUsers: false,
  isLoadingDetail: false,
  isLoadingSpend: false,
  isLoadingConsolidation: false,
  isLoadingSupplierList: false,
  isLoadingLookUpSuggestions: false,
  isHistoricalSpendData: false,
  companyStats: List([]),
  searchGraph: List([]),
  commonSearchTerms: List([]),
  companyTags: List([]),
  mostActiveSuppliers: List([]),
  mostActiveUsers: List([]),
  spend: Map({
    useSpendGroup: false,
    onlyPreferred: false,
    onlyDiverse: false,
    categories: List([]),
    countries: List([]),
    spendgroups: List([]),
  }),
  tier2: Map({
    selectedCategories: List([]),
    data: Map({}),
    totalSpend: Map({}),
  }),
  saveReports: Map({
    isLoading: false,
    isSaving: false,
    isDeleting: Map({}),
    byId: Map({}),
    SnapshotLineItems: Map({}),
  }),
  internalStats: Map({}),
  sustainabilityReport: Map({
    isLoading: false,
    isLoadingDetail: false,
    isLoadingQuestionStats: false,
    openQuestionsStatsDialog: false,
    overview: Map({}),
    completedSuppliers: List([]),
    completedSuppliersByQuestion: List([]),
    details: Map({}),
    showQuestions: Map({}),
  }),
})

const insightsReducer = (
  state = defaultState,
  action
): RecordOf<InsightsState> => {
  switch (action.type) {
    case actionTypes.LOAD_INSIGHTS:
      return action.payload.from
        ? // @ts-ignore
        state.set(`isLoading${action.payload.from}`, true)
        : state.set('isLoading', true)

    case actionTypes.LOAD_SPEND:
      return state.set('isLoadingSpend', true)

    case actionTypes.LOAD_SPEND_DETAIL:
      return state.setIn(['isLoadingDetail'], true)

    case requestSuccess(actionTypes.LOAD_INSIGHTS):
      return state
        .merge(fromJS(action.payload))
        .merge(fromJS({ isLoading: false, isLoadingActiveUsers: false }))

    case requestSuccess(actionTypes.LOAD_SPEND):
      const byCategory = Object.keys(action.payload.byCategory || {})
      const byCountry = Object.keys(action.payload.byCountry || {})
      const bySpendGroup = Object.keys(action.payload.bySpendGroup || {})

      return state
        .setIn(['spend', 'overview'], fromJS(action.payload))
        .setIn(['isLoadingSpend'], false)
        .updateIn(['spend', 'categories'], (categories) =>
          categories.filter((category) => byCategory.includes(category))
        )
        .updateIn(['spend', 'countries'], (countries) =>
          countries.filter((country) => byCountry.includes(country))
        )
        .updateIn(['spend', 'spendgroups'], (spendgroups) =>
          spendgroups.filter((spendgroup) => bySpendGroup.includes(spendgroup))
        )

    case requestSuccess(actionTypes.LOAD_SPEND_DETAIL):
      return state
        .setIn(['spend', 'preview'], fromJS(action.payload.preview))
        .setIn(['spend', 'selectedSpend'], action.payload.total || 0)
        .setIn(['isLoadingDetail'], false)

    case requestSuccess(actionTypes.GENERATE_DIVERSITY_REPORT):
    case requestSuccess(actionTypes.LOAD_REDUCED_DIVERSITY_REPORT):
    case requestSuccess(actionTypes.LOAD_SUSTAINABILITY_SPEND_REPORT):
    case requestSuccess(actionTypes.LOAD_REDUCED_SUSTAINABILITY_SPEND_REPORT):
      return state.setIn(
        ['spend', 'selectedSpend'],
        action.payload.totalSpend || 0
      )

    case actionTypes.USE_SPEND_GROUP:
      return state.setIn(['spend', 'useSpendGroup'], action.payload)

    case actionTypes.ONLY_DIVERSE:
      return state.setIn(['spend', 'onlyDiverse'], action.payload)

    case actionTypes.ONLY_PREFERRED:
      return state.setIn(['spend', 'onlyPreferred'], action.payload)

    case actionTypes.SET_SPEND_CATEGORY:
      return typeof action.payload === 'string'
        ? state.updateIn(['spend', 'categories'], (categories) =>
          categories.includes(action.payload)
            ? categories.filter((value) => value !== action.payload)
            : categories.push(action.payload)
        )
        : state.setIn(['spend', 'categories'], List(action.payload))

    case actionTypes.SET_SPEND_COUNTRY:
      return typeof action.payload === 'string'
        ? state.updateIn(['spend', 'countries'], (countries) =>
          countries.includes(action.payload)
            ? countries.filter((value) => value !== action.payload)
            : countries.push(action.payload)
        )
        : state.setIn(['spend', 'countries'], List(action.payload))

    case actionTypes.SET_SPEND_SPEND_GROUP:
      return typeof action.payload === 'string'
        ? state.updateIn(['spend', 'spendgroups'], (spendgroups) =>
          spendgroups.includes(action.payload)
            ? spendgroups.filter((value) => value !== action.payload)
            : spendgroups.push(action.payload)
        )
        : state.setIn(['spend', 'spendgroups'], List(action.payload))

    case requestSuccess(actionTypes.GET_SPEND_YEARS):
      return state.mergeIn(['spend'], fromJS(action.payload))

    case requestSuccess(LOAD_COMMUNITY_INSIGHT):
      return state.setIn(
        ['communityById', action.payload.communityId],
        fromJS(action.payload.communityInsight)
      )

    case actionTypes.LOAD_CONSOLIDATION:
      return state.set('isLoadingConsolidation', true)

    case requestSuccess(actionTypes.LOAD_CONSOLIDATION):
      return state
        .set('isLoadingConsolidation', false)
        .set('consolidation', fromJS(action.payload))

    case actionTypes.GET_TIER2_RANGE:
      return state.setIn(['tier2','isLoadingRange'], true)
    case requestSuccess(actionTypes.GET_TIER2_RANGE):
      return action.payload && action.payload.min
        ? state
          .setIn(['tier2', 'minYear'], action.payload.min.year)
          .setIn(['tier2', 'minQuarter'], action.payload.min.quarter)
          .setIn(['tier2', 'maxYear'], action.payload.max.year)
          .setIn(['tier2', 'maxQuarter'], action.payload.max.quarter)
          .setIn(['tier2','isLoadingRange'], false)
        : state

    case actionTypes.GET_TIER2_AGENCIES:
      return state
        .setIn(['tier2', 'certAgencies'], undefined)
        .setIn(['tier2', 'data'], Map({}))
        .setIn(['tier2', 'totalSpend'], Map({}))

    case requestSuccess(actionTypes.GET_TIER2_AGENCIES):
      return action.payload
        ? state.setIn(['tier2', 'certAgencies'], List(action.payload))
        : state

    case actionTypes.LOAD_TIER2_DATA:
      return state.setIn(['tier2', 'isLoading'], true)

    case requestSuccess(actionTypes.LOAD_TIER2_DATA):
      const { key, data, totalAmount, uniqueTotalTier2Spend } = action.payload
      return !key
        ? state.setIn(['tier2', 'isLoading'], false)
        : state
          .setIn(['tier2', 'isLoading'], false)
          .setIn(['tier2', 'data', key], fromJS(data))
          .setIn(['tier2', 'totalSpend', key], totalAmount)
          .setIn(
            ['tier2', 'uniqueTotalTier2Spend', key],
            uniqueTotalTier2Spend
          )

    case actionTypes.SELECT_TIER2_CATEGORY:
      return state.updateIn(
        ['tier2', 'selectedCategories'],
        (selectedCategories) =>
          selectedCategories
            ? selectedCategories.includes(action.payload)
              ? selectedCategories.filter((cat) => cat !== action.payload)
              : selectedCategories.push(action.payload)
            : List([action.payload])
      )

    case actionTypes.SELECT_ALL_TIER2_CATEGORIES:
      return state.setIn(['tier2', 'selectedCategories'], action.payload)

    case actionTypes.CLEAR_ALL_TIER2_CATEGORIES:
      return state.setIn(['tier2', 'selectedCategories'], List([]))

    case actionTypes.LOAD_TIER2_SUPPLIER_LIST:
      return state.set('isLoadingSupplierList', true)

    case requestSuccess(INVITE_TO_COMMUNITIES):
    case requestSuccess(actionTypes.LOAD_TIER2_SUPPLIER_LIST):
      return action.payload.inviteToTier2Community
        ? state.setIn(
          ['tier2', 'supplierList'],
          fromJS(action.payload.inviteToTier2Community)
        )
        : state
          .setIn(['tier2', 'supplierList'], fromJS(action.payload))
          .setIn(['isLoadingSupplierList'], false)

    case actionTypes.SEARCH_TIER2_SUPPLIERS:
      return state
        .setIn(['tier2', 'search', 'queryString'], action.payload)
        .setIn(['tier2', 'search', 'numberOfSuppliersToShow'], action.payload)
        .set('isLoadingLookUpSuggestions', true)

    case requestSuccess(actionTypes.SEARCH_TIER2_SUPPLIERS):
      const { results, resultsCount } = action.payload
      return state
        .setIn(['tier2', 'search', 'results'], fromJS(results))
        .setIn(['tier2', 'search', 'resultsCount'], resultsCount)
        .set('isLoadingLookUpSuggestions', false)

    case LOAD_INTERNAL_STATS:
      return state.set('isLoadingInternalStats', true)

    case requestSuccess(LOAD_INTERNAL_STATS):
      return state
        .setIn(
          ['internalStats', 'internalStatsQueries', action.payload.type],
          fromJS(action.payload.response)
        )
        .set('isLoadingInternalStats', false)

    case LOAD_INTERNAL_STAT_KEYS:
      return state.set('isLoadingInternalStatKeys', true)

    case requestSuccess(LOAD_INTERNAL_STAT_KEYS):
      return state
        .setIn(['internalStats', 'internalStatsKeys'], fromJS(action.payload))
        .set('isLoadingInternalStatKeys', false)

    case actionTypes.SAVE_SNAPSHOT:
      return state.setIn(['saveReports', 'isSaving'], true)

    case requestSuccess(actionTypes.SAVE_SNAPSHOT):
      return state
        .setIn(['saveReports', 'isSaving'], false)
        .setIn(
          ['saveReports', 'byId', action.payload.snapshot.id],
          fromJS(action.payload.snapshot)
        )
        .setIn(
          ['saveReports', 'SnapshotLineItems', action.payload.snapshot.id],
          fromJS(action.payload.lineItems)
        )

    case actionTypes.GET_SNAPSHOT_LIST:
    case actionTypes.GET_SNAPSHOT:
      return state.setIn(['saveReports', 'isLoading'], true)

    case requestSuccess(actionTypes.GET_SNAPSHOT_LIST):
      const byId = action.payload?.reduce((result, snapshot) => {
        result[snapshot.id] = snapshot
        return result
      }, {})

      return state
        .setIn(['saveReports', 'byId'], fromJS(byId))
        .setIn(['saveReports', 'isLoading'], false)

    case requestSuccess(actionTypes.GET_SNAPSHOT):
      return state
        .setIn(
          ['saveReports', 'byId', action.payload.snapshot.id],
          fromJS(action.payload.snapshot)
        )
        .setIn(
          ['saveReports', 'SnapshotLineItems', action.payload.snapshot.id],
          fromJS(action.payload.lineItems)
        )
        .setIn(['saveReports', 'isLoading'], false)

    case actionTypes.BULK_DELETE_SNAPSHOTS:
      const deletingSnapshots =
        action.payload?.reduce((result, id) => {
          result[id] = true
          return result
        }, {}) || {}

      return state.mergeIn(
        ['saveReports', 'isDeleting'],
        fromJS(deletingSnapshots)
      )

    case requestSuccess(actionTypes.DELETE_SNAPSHOT):
      return state
        .deleteIn(['saveReports', 'byId', action.payload])
        .deleteIn(['saveReports', 'SnapshotLineItems', action.payload])
        .setIn(['saveReports', 'isDeleting', action.payload], false)

    case actionTypes.LOAD_SUSTAINABILITY_REPORT_OVERVIEW:
      return state.setIn(['sustainabilityReport', 'loading'], true)

    case requestSuccess(actionTypes.LOAD_SUSTAINABILITY_REPORT_OVERVIEW):
      return state
        .setIn(['sustainabilityReport', 'loading'], false)
        .setIn(
          ['sustainabilityReport', 'overview'],
          fromJS(action.payload.overview)
        )
        .setIn(
          ['sustainabilityReport', 'completedSuppliers'],
          fromJS(action.payload.completedSuppliers)
        )

    case actionTypes.TOGGLE_ESG_REPORT_QUESTION:
      return typeof action.payload === 'string'
        ? state.setIn(
          ['sustainabilityReport', 'showQuestions', action.payload],
          !state.getIn([
            'sustainabilityReport',
            'showQuestions',
            action.payload,
          ])
        )
        : state.mergeIn(
          ['sustainabilityReport', 'showQuestions'],
          fromJS(
            action.payload.reduce((mergeState, id) => {
              mergeState[id] = true
              return mergeState
            }, {})
          )
        )

    case actionTypes.COLLAPSE_ALL_ESG_REPORT_QUESTIONS:
      return state.mergeIn(
        ['sustainabilityReport', 'showQuestions'],
        fromJS(
          action.payload.reduce((mergeState, id) => {
            mergeState[id] = false
            return mergeState
          }, {})
        )
      )

    case actionTypes.GET_ESG_REPORT_PAGE:
      return state.setIn(['sustainabilityReport', 'isLoadingDetail'], true)

    case requestSuccess(actionTypes.GET_ESG_REPORT_PAGE):
      return state
        .setIn(['sustainabilityReport', 'isLoadingDetail'], false)
        .mergeIn(['sustainabilityReport', 'details'], fromJS(action.payload))

    case requestFailure(actionTypes.GET_ESG_REPORT_PAGE):
      return state.setIn(['sustainabilityReport', 'isLoadingDetail'], false)

    case actionTypes.GET_SUPPLIERS_BY_QUESTION:
      return state
        .setIn(['sustainabilityReport', 'isLoadingQuestionStats'], true)
        .setIn(
          ['sustainabilityReport', 'completedSuppliersByQuestion'],
          List([])
        )
        .setIn(['sustainabilityReport', 'openQuestionsStatsDialog'], false)

    case requestSuccess(actionTypes.GET_SUPPLIERS_BY_QUESTION):
      return state
        .setIn(['sustainabilityReport', 'isLoadingQuestionStats'], false)
        .setIn(
          ['sustainabilityReport', 'completedSuppliersByQuestion'],
          fromJS(action.payload)
        )
        .setIn(['sustainabilityReport', 'openQuestionsStatsDialog'], true)

    case requestFailure(actionTypes.GET_SUPPLIERS_BY_QUESTION):
      return state.setIn(
        ['sustainabilityReport', 'isLoadingQuestionStats'],
        false
      )

    case actionTypes.CLOSE_SUPPLIERS_BY_QUESTION_DIALOG:
      return state.setIn(
        ['sustainabilityReport', 'openQuestionsStatsDialog'],
        false
      )
    case actionTypes.SWITCH_TO_HISTORICAL_SPEND_DATA:
      return state.set('isHistoricalSpendData', action.payload)
    default:
      return state
  }
}

export default insightsReducer