import React, { Component } from 'react'
import createEditForm from 'shared/utils/createEditForm'
import Input from 'shared/components/Input'
import Label from 'shared/components/Label'
import { Field } from 'redux-form/immutable'
import { connect } from 'react-redux'
import { SAVE_COMPANY_INFO } from '../../../actionTypes'
import companySelectors from '../../../../shared/selectors/companySelectors'
import ExternalLink from 'shared/components/ExternalLink'
import { validateUrl } from 'shared/utils/data/validate'
import { FormattedMessage } from 'react-intl'
import RssFeedIcon from '@material-ui/icons/RssFeed'

const EditForm = createEditForm({
  formName: 'supplier/company/blogUrl',
  submitActionType: SAVE_COMPANY_INFO,
})

type Props = {
  blogUrl: string
  isFrozen: boolean
  isTealbot: boolean
}

export class BlogUrlEditForm extends Component<Props> {
  render() {
    const { blogUrl, isFrozen, isTealbot } = this.props

    return (
      <FormattedMessage
        id='BlogUrlEditFrom.InTouchWithBlog'
        defaultMessage='Let people stay in touch with your blog.'
      >
        {(message) => (
          <EditForm
            placeholder={message}
            initialValues={{
              supplier: {
                links: {
                  blogUrl,
                },
              },
            }}
            showReadModeContent={!!blogUrl}
            readModeContent={
              <div>
                <h3 className='f7 fw6 mid-gray ma0 mb2'>
                  <FormattedMessage
                    id='BlogUrlEditForm.BlogText'
                    defaultMessage='Blog'
                  />
                </h3>
                {blogUrl && (
                  <ExternalLink href={blogUrl}>
                    <div className='flex items-center dim'>
                      <RssFeedIcon fontSize='large' />
                      <span className='ml2'>
                        <FormattedMessage
                          id='BlogUrlEditForm.VisitOurBlogText'
                          defaultMessage='Visit our blog'
                        />
                      </span>
                    </div>
                  </ExternalLink>
                )}
              </div>
            }
            editButtonTitle='edit resource blog'
            canEditSection={!isFrozen || isTealbot}
          >
            <Label>
              <FormattedMessage
                id='BlogUrlEditForm.BlogUrlLabel'
                defaultMessage='Blog Url'
              />
            </Label>

            <FormattedMessage
              id='BlogUrlEditForm.ExampleBlogUrl'
              defaultMessage='e.g. https://tealbook.com/blog/'
            >
              {(message) => (
                <Field
                  name='supplier.links.blogUrl'
                  component={Input}
                  validate={[validateUrl]}
                  placeholder={message as string}
                />
              )}
            </FormattedMessage>
          </EditForm>
        )}
      </FormattedMessage>
    )
  }
}

export default connect((state) => ({
  blogUrl: companySelectors.getLink(state, 'blogUrl'),
}))(BlogUrlEditForm)
