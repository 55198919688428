import React, { useState, useEffect } from 'react'
import Page from 'shared/components/Page'
import { FormattedMessage } from 'react-intl'
import Paper from 'shared/components/Paper'
import Slider from '@material-ui/core/Slider'
import Divider from 'shared/components/Divider'
import {
  getDateFromQuarter,
  generateMarks,
  getTextFromQuarter,
  getQuarterFromDate,
} from 'shared/utils/sliderUtilsForSpendAndLoader'
import { withStyles } from '@material-ui/styles'
import { useSelector, useDispatch } from 'react-redux'
import insightsSelectors from '../../store/insightsSelectors'
import sustainabilityReportSelectors from '../../store/sustainabilityReportSelectors'
import RootState from 'shared/models/RootState'
import useThemeColors from 'shared/utils/useThemeColors'
import { Skeleton } from '@material-ui/lab'
import numberFormat from 'shared/utils/numberFormat'
import moment from 'moment'
import {
  loadReducedSustainabilitySpendReport,
  switchToHistoricalSpendData,
} from '../../store/actions'
import TotalBox from 'shared/components/TotalBox'
import SustainabilitySpendOverview from './SustainabilitySpendOverview'
import SustainabilitySpendDetails from './SustainabilitySpendDetails'
import qs from 'qs'
import { useLocation } from 'react-router'
import Loading from 'shared/components/Loading'
import analytics from 'shared/utils/analytics'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import Switch from 'shared/components/Switch'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import Tooltip from 'shared/components/Tooltip'
import Text from 'shared/components/Text'

const CustomSlider = withStyles({
  root: {
    marginTop: 14,
    marginLeft: 8,
    width: '90%',
  },
  mark: {
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markLabel: {
    marginLeft: '6px',
    fontSize: '12px',
  },
})(Slider)

const SustainabilitySpendReport = () => {
  const dispatch = useDispatch()
  const colors = useThemeColors()
  const location = useLocation()

  const years = useSelector(insightsSelectors.getSpendYears)
  const yearEnd = useSelector((state: RootState) =>
    state.getIn(['buyer', 'settings', 'yearEnd'])
  )
  const minSpendDate = useSelector(insightsSelectors.getMinSpendDate)
  const maxSpendDate = useSelector(insightsSelectors.getMaxSpendDate)
  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const orgUnitName: string = useSelector(
    orgsSelectors.getCurrentUserOrgUnitName
  )
  const isTealbot = useSelector((state: RootState) =>
    sessionSelectors.userHasRole(state, 'tealbot')
  )
  const totalSpend = useSelector((state: RootState) =>
    state.getIn(['buyer', 'diversityReport', 'totalSpend'])
  )
  const isLoadingSpend = useSelector((state: RootState) =>
    state.getIn(['buyer', 'diversityReport', 'loading'])
  )
  const currentStartDate = useSelector((state: RootState) =>
    state.getIn(['buyer', 'diversityReport', 'startDate'])
  )
  const currentEndDate = useSelector((state: RootState) =>
    state.getIn(['buyer', 'diversityReport', 'endDate'])
  )
  const isHistoricalSpendData = useSelector((state: RootState) =>
    state.getIn(['buyer', 'insights', 'isHistoricalSpendData'])
  )
  const totals = useSelector(sustainabilityReportSelectors.getOverviewTotals)

  const { show } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const [fromQuarter, setFromQuarter] = useState<number>(() =>
    currentStartDate ? getQuarterFromDate(currentStartDate) : 0
  )
  const [toQuarter, setToQuarter] = useState<number>(() =>
    currentEndDate ? getQuarterFromDate(currentEndDate) : 0
  )

  const [startDate, setStartDate] = useState<number>(fromQuarter)
  const [endDate, setEndDate] = useState<number>(toQuarter)

  const defaultValue = [fromQuarter, toQuarter]

  const qualifiedSpend = totals?.qualified?.amount || 0
  const potentialSpend = totals?.potential?.amount || 0
  const disqualifiedSpend = totals?.disqualified?.amount || 0
  const qualifiedCount = totals?.qualified?.count || 0
  const potentialCount = totals?.potential?.count || 0
  const disqualifiedCount = totals?.disqualified?.count || 0
  const [mountTime] = useState(moment().toDate().getTime())
  useEffect(() => {
    return () => {
      analytics.track('Sustainability Spend Report Viewed', {
        duration: Number((moment().diff(mountTime) / 1000).toFixed(2)),
        orgUnitId,
        orgUnitName,
      })
    }
  }, [mountTime, orgUnitId, orgUnitName])

  useEffect(() => {
    // figure out start of the slider, if max date more than a year after min date
    // set fromQuarter a year before max date
    // else set fromQuarter to min date
    if (maxSpendDate && minSpendDate && !currentStartDate && !currentEndDate) {
      const rangeOverYear = maxSpendDate.diff(minSpendDate, 'years', true)
      const from =
        rangeOverYear >= 1
          ? getQuarterFromDate(
              moment(maxSpendDate).subtract(3, 'quarters'),
              yearEnd
            )
          : getQuarterFromDate(minSpendDate, yearEnd)

      setFromQuarter(from)
      setToQuarter(getQuarterFromDate(maxSpendDate, yearEnd))
    }
  }, [maxSpendDate, minSpendDate, yearEnd, currentStartDate, currentEndDate])

  useEffect(() => {
    if (fromQuarter && toQuarter) {
      const payload = {
        startDate: getDateFromQuarter(fromQuarter, false, yearEnd),
        endDate: getDateFromQuarter(toQuarter, true, yearEnd),
        historical: isHistoricalSpendData,
      }
      dispatch(
        loadReducedSustainabilitySpendReport({ reduce: true, ...payload })
      )
    }
  }, [fromQuarter, toQuarter, yearEnd, isHistoricalSpendData, dispatch])

  const handleChange = (event, value: [number, number]) => {
    setStartDate(value[0])
    setEndDate(value[1])
  }

  const handleChangeCommitted = (event, value: [number, number]) => {
    if (value.length === 2) {
      setFromQuarter(value[0])
      setToQuarter(value[1])
    }
  }

  return (
    <Page
      title={
        <FormattedMessage
          id='SustainabilitySpendReport.SustainabilitySpendReport'
          defaultMessage='Sustainability Certifications Spend Report'
        />
      }
    >
      {years && years.size > 0 ? (
        <>
          <Paper>
            <div className='flex justify-between items-center flex-wrap'>
              <div className='w-100 w-50-ns'>
                {!!fromQuarter && !!toQuarter && (
                  <h3
                    className='mt3 mb0 f5 mid-gray fw6 flex items-center justify-between pr3'
                    id='reportRange'
                  >
                    {fromQuarter === toQuarter &&
                      getTextFromQuarter(fromQuarter)}
                    {fromQuarter !== toQuarter && (
                      <FormattedMessage
                        id='SustainabilitySpendReport.DateRangeReport'
                        defaultMessage='Report on {fromQuarter} to {toQuarter}'
                        values={{
                          fromQuarter: getTextFromQuarter(fromQuarter),
                          toQuarter: getTextFromQuarter(toQuarter),
                        }}
                      />
                    )}
                    {yearEnd && yearEnd !== '12/31' && (
                      <span
                        className={`dib white pa2 f8 ${colors.primaryContained}`}
                      >
                        <FormattedMessage
                          id='SustainabilitySpendReport.YearEnd'
                          defaultMessage='Year End'
                        />
                        &nbsp;{yearEnd}
                      </span>
                    )}
                  </h3>
                )}
                {minSpendDate && maxSpendDate && (
                  <CustomSlider
                    aria-labelledby='reportRange'
                    step={25}
                    min={getQuarterFromDate(minSpendDate, yearEnd)}
                    max={getQuarterFromDate(maxSpendDate, yearEnd)}
                    onChange={handleChange}
                    value={
                      startDate && endDate ? [startDate, endDate] : defaultValue
                    }
                    onChangeCommitted={handleChangeCommitted}
                    marks={generateMarks(
                      getQuarterFromDate(minSpendDate, yearEnd),
                      getQuarterFromDate(maxSpendDate, yearEnd),
                      startDate && endDate ? [startDate, endDate] : defaultValue
                    )}
                    getAriaValueText={getTextFromQuarter}
                    orientation='horizontal'
                  />
                )}
                {isTealbot && (
                  <div className='mt1'>
                    <Switch
                      ariaLabel='historical valid certifications'
                      label={
                        <div className='flex justify-between items-center flex-wrap'>
                          <FormattedMessage
                            id='SustainabilityPage.ViewHistoricallyValidCertifications'
                            defaultMessage='View Historically Valid Certifications'
                          />
                          <Tooltip
                            title={
                              <Text>
                                <FormattedMessage
                                  id='SustainabilityPage.ViewHistoricallyValidCertificationsTooltip'
                                  defaultMessage={`Toggling this feature on will change the data that you see in this report to reflect certification data that was valid during the selected reporting period. This is in contrast to the default view, which reflects certification data that is valid today.`}
                                />
                              </Text>
                            }
                          >
                            <InfoIcon className='ml2' color='primary' />
                          </Tooltip>
                        </div>
                      }
                      checked={isHistoricalSpendData}
                      onChange={() =>
                        dispatch(
                          switchToHistoricalSpendData(!isHistoricalSpendData)
                        )
                      }
                    />
                  </div>
                )}
              </div>
              <div className='w-100 w-25-ns'>
                <div className='tr f7'>
                  <FormattedMessage
                    id='SustainabilitySpendReport.TotalSpend'
                    defaultMessage='Total Spend'
                  />
                </div>
                <div className='tr f5 fw6 '>
                  {isLoadingSpend && <Skeleton animation='wave' height={23} />}
                  {!isLoadingSpend && `$${numberFormat(totalSpend || 0, 0)}`}
                </div>
              </div>
            </div>
            <Divider className='mt1' />
            <div className='bg-near-white pa2 flex justify-between flex-wrap mt3'>
              <div
                className={`w-100 w-25-ns mid-gray br-ns b--black-20 bw1 ph2`}
              >
                <TotalBox
                  isLoading={isLoadingSpend}
                  colorClass={'mid-gray'}
                  label={
                    <FormattedMessage
                      id='SustainabilitySpendReport.SustainabilityTotal'
                      defaultMessage='Sustainability Total'
                    />
                  }
                  value={qualifiedSpend + potentialSpend + disqualifiedSpend}
                  count={qualifiedCount + potentialCount + disqualifiedCount}
                />
              </div>
              <div
                className={`w-100 w-25-ns mid-gray br-ns b--black-20 bw1 ph2 bn bg-transparent`}
              >
                <TotalBox
                  isLoading={isLoadingSpend}
                  colorClass={`dark-teal`}
                  label={
                    <FormattedMessage
                      id='SustainabilitySpendReport.Qualified'
                      defaultMessage='Sustainability Qualified'
                    />
                  }
                  value={qualifiedSpend}
                  count={qualifiedCount}
                />
              </div>
              <div
                className={`w-100 w-25-ns mid-gray br-ns b--black-20 bw1 ph2 bn bg-transparent`}
              >
                <TotalBox
                  isLoading={isLoadingSpend}
                  htmlColor={'#986801'}
                  label={
                    <FormattedMessage
                      id='SustainabilitySpendReport.Potential'
                      defaultMessage='Sustainability Potential'
                    />
                  }
                  value={potentialSpend}
                  count={potentialCount}
                />
              </div>
              <div className={`w-100 w-25-ns mid-gray ph2 bn bg-transparent`}>
                <TotalBox
                  isLoading={isLoadingSpend}
                  colorClass={'dark-red'}
                  label={
                    <FormattedMessage
                      id='SustainabilitySpendReport.Disqualified'
                      defaultMessage='Sustainability Disqualified'
                    />
                  }
                  value={disqualifiedSpend}
                  count={disqualifiedCount}
                />
              </div>
            </div>
          </Paper>
          {isLoadingSpend ? (
            <Loading />
          ) : (
            <>
              <SustainabilitySpendOverview
                headerLabel={
                  <FormattedMessage
                    id='SustainabilitySpendReport.SustainabilityReport'
                    defaultMessage='Sustainability Report'
                  />
                }
              />
              <SustainabilitySpendDetails
                show={show}
                headerLabel={
                  <FormattedMessage
                    id='SustainabilitySpendReport.SpendDetails'
                    defaultMessage='Spend Details'
                  />
                }
              />
            </>
          )}
        </>
      ) : (
        <Paper>
          <h5 className='mt3-5 tc mid-gray f7 black fw6 ma0'>
            <FormattedMessage
              id='SustainabilitySpendReport.ProvideTealbookWithYourHistoricalSpend'
              defaultMessage='Provide TealBook with your historical spend data to unlock new insights'
            />
          </h5>
        </Paper>
      )}
    </Page>
  )
}

export default SustainabilitySpendReport
