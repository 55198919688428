import React from 'react'
import Label from 'shared/components/Label'
import { FormattedMessage } from 'react-intl'
import Text from 'shared/components/Text'
import { AllocatedSpendState } from '../../store/tier2Reducer'
import { OrgUnit } from 'shared/containers/OrgUnit/OrgUnit'
import { SpendByQuarterMap } from 'supplier/Insight/containers/Report'
import SpendAllocation, { SpendAllocationProps } from './SpendAllocation'

type Props = {
  allocatedSpend: AllocatedSpendState
  spendByQuarterMap: SpendByQuarterMap
} & Pick<SpendAllocationProps, 'onShareSpendAllocation'>

const unmappedSupplierId = 'OrgUnit-5157071787917312-5742743685693440'

const ShareTier2 = ({
  allocatedSpend,
  onShareSpendAllocation,
  spendByQuarterMap,
}: Props) => (
  <>
    <div className='mb2'>
      <Label>
        <FormattedMessage
          id='ShareTier2.AllocatingShare1'
          defaultMessage='Allocate diversity spend to your customers'
        />
      </Label>
    </div>
    <Text>
      <FormattedMessage
        id='ShareTier2.ShareExplanation1'
        defaultMessage='When you share your spend information, your customer will receive a simple list with the total spend amount you’ve allocated to them for each diversity category within the specified calendar quarter. No other information is shared.'
      />
    </Text>
    {allocatedSpend.data?.map((item) => (
      <div className='ph3 pb3 b--light-gray ba mt3' key={item.buyerOrgUnitId}>
        <div role='list'>
          <OrgUnit id={item.buyerOrgUnitId} name={item.buyerName} />
        </div>
        {spendByQuarterMap.get(item.buyerOrgUnitId)?.map((spend) => {
          const allocatedSpendYear = item.allocatedSpend[spend.year]
          const allocatedSpendQuarter =
            allocatedSpendYear && allocatedSpendYear[spend.quarter]
          if (allocatedSpendQuarter?.isDirFileIndirAgg) {
            allocatedSpendQuarter.diverseSupplierAllocations =
              allocatedSpendQuarter.diverseSupplierAllocations.filter(
                (supplier) => supplier.id !== unmappedSupplierId
              )
          }

          return (
            <SpendAllocation
              key={`${item.buyerOrgUnitId}${spend.dataType}${spend.year}${spend.quarter}`}
              {...spend}
              {...allocatedSpendQuarter}
              buyerId={item.buyerOrgUnitId}
              communityPlanMembershipId={item.communityPlanMembershipId}
              onShareSpendAllocation={onShareSpendAllocation}
            />
          )
        })}
      </div>
    ))}
  </>
)

export default ShareTier2
