import React, { useEffect, useState } from 'react'
import { List, RecordOf } from 'immutable'
import { Column, TableCellProps } from 'react-virtualized'
import startCase from 'lodash.startcase'
import { FormattedMessage } from 'react-intl'
import { RouteComponentProps } from 'react-router'
import { CSSProperties } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'

import VTable from 'shared/components/Table'
import Avatar from 'shared/components/Avatar'
import Checkbox from 'shared/components/Checkbox'
import DropDownMenu from 'shared/components/DropDownMenu'
import MenuItem from 'shared/components/MenuItem'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'

import dateFormat from 'shared/utils/dateFormat'
import parsePath from 'shared/utils/parsePath'
import canDeleteUser from 'shared/utils/canDeleteUser'
import useSmallScreen from 'shared/utils/useSmallScreen'
import User from 'shared/models/User'

import buyerPaths from '../../../../buyer/routes/paths'
import supplierPaths from '../../../../supplier/routes/paths'
import DeleteAccount from '../../../../myaccount/components/DeleteAccount'
import BulkActions from '../../../containers/BulkActions'
import BulkErrorMessage from '../../../containers/BulkActions/BulkErrorMessage'
import insight from 'shared/utils/api/insight'
import exportData from 'shared/utils/exportData'
import logger from 'shared/utils/logger'

const styles: CSSProperties = {
  inactiveUserRow: {
    cursor: 'pointer',
    background: '#f5f5f5',
    color: '#000000de'
  },
  activeUserRow: {
    cursor: 'pointer'
  }
}

type UsersSelected = Partial<{
  userId: {
    id: string
    roles: string[]
    status: string
    firstName: string
    lastName: string
  }
}>

type Props = {
  users: List<RecordOf<User>>
  isClientApp: boolean
  onClickUser: () => void
  currentUser: RecordOf<User>
  isTealbot: boolean
  bulkUsersSelected: UsersSelected
  selectBulkUsers: (usersSelected: UsersSelected) => void
  defaultLicenseType: string
  resendUserInvite: (payload: {
    userId: string[]
    currentOrgUnit: string
  }) => void
  isSupplierAdmin: boolean
  isBuyerAdmin: boolean
  currentOrgUnit: string
} & RouteComponentProps

export const UserList = ({
  users,
  history,
  isClientApp,
  currentUser,
  isTealbot,
  bulkUsersSelected,
  selectBulkUsers,
  defaultLicenseType,
  resendUserInvite,
  isSupplierAdmin,
  isBuyerAdmin,
  currentOrgUnit
}: Props) => {
  const [userToDelete, setUserToDelete] = useState<string | undefined>(
    undefined
  )
  const [anchorEl, setAnchorEl] = useState<HTMLImageElement | null>(null)
  const [nameQuery, setNameQuery] = useState('')
  const [emailQuery, setEmailQuery] = useState('')
  const [filteredUsers, setFilteredUsers] = useState(users)
  const [exportClicked, setExportClicked] = useState<boolean>(false)

  useEffect(() => setFilteredUsers(users), [users])

  const matchMobile = useSmallScreen()

  const getRow = ({ index }) => filteredUsers.get(index)

  const navigateToUserProfile = userId => {
    if (isClientApp) {
      history.push(parsePath(buyerPaths.userprofile, { userId }))
    } else {
      history.push(parsePath(supplierPaths.userProfile, { userId }))
    }
  }

  const filterNames = e => {
    setNameQuery(e.target.value)
    filterNameAndEmail(e.target.value, emailQuery)
  }

  const filterEmails = e => {
    setEmailQuery(e.target.value)
    filterNameAndEmail(nameQuery, e.target.value)
  }

  const nameFilter = (user: RecordOf<User>, name: string) =>
    (user.get('firstName') &&
      user
        .get('firstName')
        .toLowerCase()
        .includes(name)) ||
    (user.get('lastName') &&
      user
        .get('lastName')
        .toLowerCase()
        .includes(name))

  const emailFilter = (user: RecordOf<User>, email: string) =>
    user.get('email') &&
    user
      .get('email')
      .toLowerCase()
      .includes(email)

  const filterNameAndEmail = (name, email) => {
    const nFilter = (user: RecordOf<User>) => nameFilter(user, name)
    const eFilter = (user: RecordOf<User>) => emailFilter(user, email)
    let tempUsers: List<RecordOf<User>> = List()

    if (name !== '' && email !== '')
      tempUsers = users.filter(user => nFilter(user) && eFilter(user))
    else if (name !== '') tempUsers = users.filter(nFilter)
    else if (email !== '') tempUsers = users.filter(eFilter)
    else tempUsers = users

    setFilteredUsers(tempUsers)
  }

  const renderNameCell = ({ rowData }: TableCellProps) => {
    const { firstName, lastName, profilePictureUrl, id } = rowData.toJS()
    const fullName = `${firstName ? firstName : ''} ${lastName ? lastName : ''}`
    return (
      <div className='flex'>
        {isTealbot && (
          <Checkbox
            key={id}
            name='userList'
            className='mv2 mr2'
            checked={bulkUsersSelected[id]}
            onChange={() => {
              let newUsersSelected = { ...bulkUsersSelected }
              if (newUsersSelected[id]) {
                delete newUsersSelected[id]
              } else {
                newUsersSelected = {
                  ...newUsersSelected,
                  [id]: rowData?.toJS()
                }
              }
              selectBulkUsers(newUsersSelected)
            }}
          />
        )}

        <div className='nowrap' onClick={() => navigateToUserProfile(id)}>
          <Avatar
            url={profilePictureUrl}
            className='mr2 w2 v-mid'
            name={fullName}
          />
          <div className='dib v-mid'>{fullName}</div>
        </div>
      </div>
    )
  }

  const renderEmailCell = ({ rowData }: TableCellProps) => {
    const { email, id } = rowData.toJS()
    return <div onClick={() => navigateToUserProfile(id)}>{email}</div>
  }

  const renderLastLoginCell = ({ rowData }: TableCellProps) => {
    const { lastLogin, id } = rowData.toJS()

    return (
      <div onClick={() => navigateToUserProfile(id)}>
        {isNaN(lastLogin && lastLogin.getTime()) ? (
          <FormattedMessage id='UserList.Never' defaultMessage='Never' />
        ) : (
          dateFormat(lastLogin)
        )}
      </div>
    )
  }
  const renderRolesCell = ({ rowData }: TableCellProps) => {
    const { roles, id } = rowData.toJS()

    return (
      <div onClick={() => navigateToUserProfile(id)}>
        {roles &&
          roles.length > 0 &&
          roles
            .sort((roleA, roleB) =>
              roleA.toUpperCase() > roleB.toUpperCase() ? 1 : -1
            )
            .map(role => startCase(role.replace('Vet', 'RFI')))
            .join(', ')}
      </div>
    )
  }

  const renderTealbotMenuCell = ({ rowData }: TableCellProps) => {
    const { roles, id, firstName, lastName } = rowData.toJS()
    return isTealbot || isSupplierAdmin || isBuyerAdmin ? (
      <DropDownMenu ariaLabel={`${firstName} ${lastName} menu`}>
        {id !== currentUser.get('id') &&
          roles &&
          canDeleteUser(roles, currentUser.get('roles')) && (
            <MenuItem onClick={() => setUserToDelete(id)}>
              <FormattedMessage
                id='UserList.deleteButton'
                defaultMessage='Delete'
              />
            </MenuItem>
          )}

        <MenuItem
          onClick={() => resendUserInvite({ userId: [id], currentOrgUnit })}
        >
          <FormattedMessage
            id='UserList.resendInvite'
            defaultMessage='Resend Tealbook Invitation'
          />
        </MenuItem>
      </DropDownMenu>
    ) : null
  }

  const renderLicenseCell = ({ cellData, rowData }: TableCellProps) => {
    const roles = rowData.get('roles')
    return (
      <span>
        {roles?.includes('buyer')
          ? cellData
            ? startCase(cellData)
            : defaultLicenseType
            ? startCase(defaultLicenseType)
            : ''
          : 'N/A'}
      </span>
    )
  }

  return (
    <>
      <div className='flex mr2'>
        {isTealbot && (
          <Button
            autoSize
            onClick={e => {
              setAnchorEl(e.currentTarget)
            }}
            label={
              <FormattedMessage
                id='UserList.BulkActions'
                defaultMessage='Bulk Actions'
              />
            }
            disabled={!Object.keys(bulkUsersSelected).length}
          />
        )}
        <BulkErrorMessage />
      </div>

      <div className='flex justify-between'>
        <div className='flex'>
          <div className='mr2'>
            <label htmlFor='nameFilter'>
              <FormattedMessage
                id='UserList.nameFilter'
                defaultMessage='Name'
              />
            </label>
            <Input name='nameFilter' value={nameQuery} onChange={filterNames} />
          </div>
          <div>
            <label htmlFor='emailFilter'>
              <FormattedMessage
                id='UserList.emailFilter'
                defaultMessage='Email'
              />
            </label>
            <Input
              name='emailFilter'
              value={emailQuery}
              onChange={filterEmails}
            />
          </div>
        </div>
        <div className='flex'>
          {(isSupplierAdmin || isBuyerAdmin || isTealbot) && (
            <Button
              className='mb3 mt3'
              label={'Export User Activity'}
              autoSize
              disabled={exportClicked}
              onClick={() => {
                setExportClicked(true)
                insight
                  .userActivity()
                  .then(response => {
                    let data = response.map(detail => {
                      return {
                        Date: detail.Date,
                        FirstName: detail.FirstName,
                        LastName: detail.LastName,
                        UserId: detail.UserId,
                        LastLogin: detail.LastLogin,
                        FirstLogin: detail.FirstLogin,
                        LicenseType: detail.LicenseType || defaultLicenseType,
                        Roles: detail.Roles,
                        FollowsByUser: detail.Follows || '0',
                        TagsAdded: detail.Tags || '0',
                        RatingsAdded: detail.Ratings || '0',
                        ContactsAdded: detail.Contacts || '0',
                        UniqueLoginsByUser: detail.Logins || '0',
                        SearchesAndFiltersApplied: detail.Searches || '0',
                        ProfilesViewed: detail.ProfileViews || '0',
                        RfisCreated: detail.RfiCreated || '0'
                      }
                    }) 
                    data = data.sort((a,b) => a.Date > b.Date ? -1 : 1)
                    setExportClicked(false)
                    return exportData.exportCSV(data, 'UserActivity')
                  })
                  .catch(err => {
                    logger.log(err)
                    setExportClicked(false)
                  })
              }}
            />
          )}
        </div>
      </div>

      <Paper className={'mt2'}>
        <VTable
          onRow
          minWidth={692}
          rowGetter={getRow}
          rowCount={filteredUsers.size}
          rowClassName='pointer dim'
          height={600}
          rowStyle={({ index }) => {
            const rowStyling = filteredUsers.get(index)
            return rowStyling && rowStyling.get('status') === 'Normal'
              ? styles.activeUserRow
              : styles.inactiveUserRow
          }}
          rowHeight={(row: any): number => {
            const userData = filteredUsers.get(row.index)
            const roles = userData && userData.get('roles')
            const numberOfRoles = roles && roles.size
            if (!matchMobile && numberOfRoles && numberOfRoles > 10) {
              return 110
            } else if (!matchMobile && numberOfRoles && numberOfRoles > 7) {
              return 90
            } else if (!matchMobile && numberOfRoles && numberOfRoles > 4) {
              return 80
            } else {
              return 40
            }
          }}
        >
          <Column
            label={
              <div className='flex'>
                {isTealbot && (
                  <Checkbox
                    key='name'
                    name='allUsers'
                    className='mr2'
                    checked={
                      Object.keys(bulkUsersSelected).length > 0 &&
                      Object.keys(bulkUsersSelected).length ===
                        filteredUsers.size
                    }
                    onChange={e => {
                      let allSelected = filteredUsers?.toJS().reduce(
                        (result, currentUser) =>
                          (result = {
                            ...result,
                            [currentUser.id]: currentUser
                          }),
                        {}
                      )
                      e.target.checked
                        ? selectBulkUsers(allSelected)
                        : selectBulkUsers({})
                    }}
                  />
                )}
                <FormattedMessage id='UserList.Name' defaultMessage='Name' />
              </div>
            }
            dataKey={'id'}
            width={400}
            cellRenderer={renderNameCell}
          />
          <Column
            label={
              <FormattedMessage id='UserList.Email' defaultMessage='Email' />
            }
            dataKey={'email'}
            width={400}
            cellRenderer={renderEmailCell}
          />
          <Column
            label={
              <FormattedMessage
                id='UserList.LastLogin'
                defaultMessage='Last Login'
              />
            }
            dataKey={'lastLogin'}
            width={150}
            cellRenderer={renderLastLoginCell}
          />
          {!matchMobile && (
            <Column
              label={
                <FormattedMessage id='UserList.Roles' defaultMessage='Roles' />
              }
              dataKey={'roles'}
              width={400}
              cellRenderer={renderRolesCell}
              style={{
                wordWrap: 'break-word',
                whiteSpace: 'normal',
                padding: '12px'
              }}
            />
          )}

          <Column
            label={
              <FormattedMessage id='UserList.Status' defaultMessage='Status' />
            }
            dataKey={'status'}
            width={150}
          />
          <Column
            label={
              <FormattedMessage
                id='UserList.License'
                defaultMessage='License'
              />
            }
            dataKey={'licenseType'}
            width={100}
            cellRenderer={renderLicenseCell}
          />
          <Column
            dataKey={'id'}
            width={60}
            cellRenderer={renderTealbotMenuCell}
          />
        </VTable>
        {!!userToDelete && (
          <DeleteAccount
            userId={userToDelete}
            hardDelete
            open={!!userToDelete}
            onClose={() => setUserToDelete(undefined)}
          />
        )}
      </Paper>
      <BulkActions
        anchorEl={anchorEl}
        onHandleCloseMenu={() => setAnchorEl(null)}
      />
    </>
  )
}

export default UserList
