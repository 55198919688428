import { createSelector } from 'reselect'
import RootState from 'shared/models/RootState'

export const getStagingAllocationBySupplierId = createSelector(
  (state: RootState, stagingId: string, supplierId: string) =>
    state.getIn([
      'supplier',
      'tier2',
      'allocatedSpendValidation',
      'allocationByStagingId',
    ]),
  (state: RootState, stagingId: string, supplierId: string) => stagingId,
  (state: RootState, stagingId: string, supplierId: string) => supplierId,
  (allocationByStagingId, stagingId, supplierId) => {
    const supplierAllocation = allocationByStagingId?.getIn([
      stagingId,
      supplierId,
    ])
    return supplierAllocation
      ? supplierAllocation
          .valueSeq()
          .reduce((sum, alloc) => sum + alloc.get(1), 0)
      : 0
  }
)

export const getOverallAllocationByStagingId = createSelector(
  (state: RootState, stagingId: string | Array<string>) =>
    state.getIn([
      'supplier',
      'tier2',
      'allocatedSpendValidation',
      'allocationByStagingId',
      stagingId,
      'overallAllocation',
    ]),
  (overallAllocation) => overallAllocation
)

export const getAllocatedSuppliersByStagingId = createSelector(
  (
    state: RootState,
    stagingId: string | Array<string>,
    isIndirectAggregate: boolean
  ) =>
    state.getIn([
      'supplier',
      'tier2',
      'allocatedSpendValidation',
      'allocationByStagingId',
    ]),
  (state: RootState, stagingId: string | Array<string>) => stagingId,
  (
    state: RootState,
    stagingId: string | Array<string>,
    isIndirectAggregate: boolean
  ) => isIndirectAggregate,
  (allocationByStagingId, stagingId, isIndirectAggregate) => {
    // supplierId -> buyerId -> allocation
    // index 0 = previous allocation, index 1 = current allocation
    let allocatedSuppliers: Array<string> = []
    if (typeof stagingId === 'string') {
      let suppliers = allocationByStagingId.get(stagingId)?.toJS() || {}
      suppliers = isIndirectAggregate ? suppliers?.overallAllocation : suppliers
      for (const supplier in suppliers) {
        if (
          Object.values(suppliers[supplier]).some(
            (allocation: number[]) => allocation[1]
          )
        ) {
          allocatedSuppliers.push(supplier)
        }
      }
    } else {
      stagingId?.forEach((id) => {
        const suppliers = allocationByStagingId.get(id)?.toJS() || {}
        for (const supplier in suppliers) {
          if (
            Object.values(suppliers[supplier]).some(
              (allocation: number[]) => allocation[1]
            )
          ) {
            allocatedSuppliers.push(supplier)
          }
        }
      })
    }

    return allocatedSuppliers
  }
)

export const isBuyerOverAllocated = createSelector(
  (state: RootState, stagingId: string, supplierId: string | Array<string>) =>
    state.getIn([
      'supplier',
      'tier2',
      'allocatedSpendValidation',
      'allocationByStagingId',
    ]),
  (state: RootState, stagingId: string | Array<string>, buyerId: string) =>
    stagingId,
  (state: RootState, stagingId: string | Array<string>, buyerId: string) =>
    buyerId,
  (allocationByStagingId, stagingId, buyerId) => {
    let buyerOverAllocatedSuppliers
    let overAllocatedSuppliers
    if (typeof stagingId === 'string') {
      const suppliers = allocationByStagingId?.get(stagingId)

      // handle special case where shared only indirect (overall) allocation
      // filter out all shared percentage (index 0) that is 0 or undefined
      // only overallAllocation has shared > 0
      if (
        suppliers?.get('overallAllocation') &&
        suppliers?.filter((supplier) =>
          supplier
            .valueSeq()
            .toJS()
            .some((v) => !!v[0])
        ).size === 1
      ) {
        // if any of the current value (index 1) has value > 100 - MAX(overallAllocation), then it is over allocated
        const maxPercentage =
          100 -
          suppliers
            .get('overallAllocation')
            .toList()
            .reduce((total, values) => total + values.get(0), 0)
        // check any of the supplier allocation from the buyer has over the max
        overAllocatedSuppliers = suppliers?.filter((supplier) => {
          // filter buyer
          const buyerValue = supplier.get(buyerId)
          //current value is less than or equal to the shared value
          if (buyerValue?.get(1) <= buyerValue?.get(0)) {
            return false
          }
          return buyerValue?.get(1) > maxPercentage
        })
      } else {
        overAllocatedSuppliers = suppliers?.filter((supplier) => {
          const total = supplier.entrySeq().reduce((sum, [k, v]) => {
            // current buyer, use form value, else use publish value
            sum += buyerId === k ? v.get(1) : v.get(0)
            return sum
          }, 0)
          return total > 100
        })
      }

      buyerOverAllocatedSuppliers = overAllocatedSuppliers?.filter(
        (supplier) => {
          return supplier.keySeq().includes(buyerId)
        }
      )
    } else {
      stagingId?.forEach((id) => {
        const suppliers = allocationByStagingId?.get(id)

        // handle special case where shared only indirect (overall) allocation
        // filter out all shared percentage (index 0) that is 0 or undefined
        // only overallAllocation has shared > 0
        if (
          suppliers?.get('overallAllocation') &&
          suppliers?.filter((supplier) =>
            supplier
              .valueSeq()
              .toJS()
              .some((v) => !!v[0])
          ).size === 1
        ) {
          // if any of the current value (index 1) has value > 100 - MAX(overallAllocation), then it is over allocated
          const maxPercentage =
            100 -
            suppliers
              .get('overallAllocation')
              .toList()
              .reduce((total, values) => total + values.get(0), 0)
          // check any of the supplier allocation from the buyer has over the max
          overAllocatedSuppliers = suppliers?.filter((supplier) => {
            // filter buyer
            const buyerValue = supplier.get(buyerId)
            // console.log(stagingId, maxPercentage, buyerValue?.toJS())
            return buyerValue?.get(1) > maxPercentage
          })
        } else {
          overAllocatedSuppliers = suppliers?.filter((supplier) => {
            const total = supplier.entrySeq().reduce((sum, [k, v]) => {
              // current buyer, use form value, else use publish value
              sum += buyerId === k ? v.get(1) : v.get(0)
              return sum
            }, 0)
            return total > 100
          })
        }

        const tempBuyerOverAllocatedSuppliers = overAllocatedSuppliers?.filter(
          (supplier) => {
            return supplier.keySeq().includes(buyerId)
          }
        )
        buyerOverAllocatedSuppliers = !buyerOverAllocatedSuppliers
          ? tempBuyerOverAllocatedSuppliers
          : buyerOverAllocatedSuppliers.concat(tempBuyerOverAllocatedSuppliers)
      })
    }

    return buyerOverAllocatedSuppliers?.keySeq()
  }
)
