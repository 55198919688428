import { call, put, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import createSubmitSaga from 'shared/utils/sagas/createSubmitSaga'
import { requestSuccess } from 'shared/actions'
import auth from 'shared/utils/api/auth'
import user from 'shared/utils/api/user'
import users from 'shared/utils/api/users'
import routingSelector from 'shared/selectors/routingSelectors'
import paths from 'shared/routes/paths'
import logger from 'shared/utils/logger'
import ApiError from 'shared/utils/api/ApiError'
import qs from 'qs'
import community from 'shared/utils/api/community'
import analytics from 'shared/utils/analytics'
import supplierPaths from 'supplier/routes/paths'

export function* loginSaga(action) {
  const payload = action.payload.toJS()
  const {
    alreadyLoggedIn,
    punchoutPayload,
    loginOptions,
    language = 'en',
    client,
    restriction,
    origEmail,
    ...restPayload
  } = payload

  const requestUrl = yield select(routingSelector.getSearchParam, 'requestUrl')
  const hash = yield select(routingSelector.getHash)
  const fromIframe = loginOptions && loginOptions.includes('iframe')

  //login
  if (!alreadyLoggedIn) {
    try {
      yield call(auth.login, restPayload)
    } catch (err) {
      // see if we need to redirect to sso: errorCode = 1
      if (err.errorCode === 1) {
        auth.removeLastLoggedInUser()
        throw new ApiError({
          clientErrorMessage:
            'You need to sign in with your identity provider. Press Continue and we will redirect you to them.'
        })
      } else {
        // continue error chain
        throw err
      }
    }
    auth.saveLastLoggedInUser({
      username: restPayload.username,
      selectedOrgUnit: restPayload.selectedOrgUnit
    })
  }

  // get user info
  const { userInfo, orgUnit } = yield call(user.get)

  // if login in with punchout then use that tokens name and email
  // if (punchoutPayload) {
  // }

  logger.login({
    id: userInfo.id,
    email: userInfo.email,
    roles: userInfo.roles
  })

  analytics.identifyUser(userInfo, orgUnit)
  analytics.track('Signed In')

  if (language !== userInfo.language) {
    yield call(users.updateById, userInfo.id, { language })
    userInfo.language = language
  }

  // trigger a successful login action
  let accessControl = 'full'
  if (!!punchoutPayload) {
    accessControl = 'punchout'
  } else if (restriction) {
    accessControl = restriction
  }

  yield put(
    requestSuccess(action.type, {
      userInfo,
      orgUnit,
      accessControl
    })
  )

  // redirect to initial page
  if (requestUrl) {
    yield put(push(`${requestUrl}${hash}`))
  } else if (fromIframe) {
    const { protocol, host } = window.location
    if (client === 'rfaq') {
      yield call(community.inviteToCommunityIframe, {
        client,
        inviteToComId: 'Community-5849740052529152'
      })
    }
    yield put(push(`${paths.redirectWindowTop}?url=${protocol}//${host}`))
  } else if (accessControl === 'punchout') {
    const punchoutUrl = getSearchUrlForPunchout(punchoutPayload)
    yield put(push(`/client/search?${punchoutUrl}`))
  } else if (accessControl === 'esgSurvey') {
    yield put(
      push(
        `${supplierPaths.esgSurvey}${
          origEmail ? `?invitedBy=${origEmail}` : ''
        }`
      )
    )
  } else {
    const appPath =
      userInfo.roles && userInfo.roles.includes('buyer')
        ? '/client'
        : '/supplier'
    yield put(push(appPath))
  }
}

function getSearchUrlForPunchout(punchoutPayload): string {
  const similarSuppliers = punchoutPayload.cart?.map(c => c.id)

  return qs.stringify({
    q: punchoutPayload.keywords,
    sort:
      !similarSuppliers || similarSuppliers.length < 1
        ? 'Relevance'
        : 'Similarity',
    scope: 'All',
    similarSuppliers,
    filter: {
      ...punchoutPayload.filter
    }
  })
}

export default createSubmitSaga(loginSaga)
