import React, { FunctionComponent, useState, useEffect } from 'react'
import { fromJS } from 'immutable'
import { connect } from 'react-redux'
import profileSelectors from '../../selectors/profileSelectors'
import { updateRelationship } from '../../actions'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import PageSection from 'shared/components/PageSection'
import ImagePreferred from 'shared/assets/icons/prefer.svg'
import ImageDoNotUse from 'shared/assets/icons/no.svg'
import ImageRestricted from 'shared/assets/icons/restrict.svg'
import ImageExisting from 'shared/assets/icons/existing.svg'
import ImageNonRecruit from 'shared/assets/icons/non-recruit.svg'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import TextArea from 'shared/components/TextArea'
import Label from 'shared/components/Label'
import EditForm from 'shared/components/EditForm'
import { FormattedMessage } from 'react-intl'
import usersSelectors from '../../../../shared/selectors/usersSelectors'
import { List, Map, RecordOf } from 'immutable'
import User from 'shared/models/User'
import RootState from 'shared/models/RootState'
import UserComment from 'shared/components/UserComment'
import buyerPaths from '../../../routes/paths'
import parsePath from 'shared/utils/parsePath'

const icons = {
  preferred: ImagePreferred,
  doNotUse: ImageDoNotUse,
  restricted: ImageRestricted,
  existing: ImageExisting,
  nonRecruit: ImageNonRecruit,
}
const styles = {
  wrapper: 'dt w-100 pb2 pt-2 ph2',
  avatar: 'br-90 w-90 pointer',
  avatarWrapper: 'dib ml2 w2',
  icon: 'h1 w1 dib v-mid mr1',
  contentWrapper: 'v-top mb2',
  iconLabelWrapper: 'f6 mid-gray',
  date: 'f8 fw4 ma0 gray lh-copy dib ml2',
  comment: 'f7 fw4 mid-gray lh-copy mt1',
}

type Props = {
  attributes: Map<
    string,
    RecordOf<{
      modified: {
        user: string
        date: string
      }
      note: string
      status: boolean
    }>
  >
  currentUserId: string
  canEditRelationshipAttributes: boolean
  hasAttributes: boolean
  currentUser: RecordOf<User>
  orderedRelationshipAttributes: List<RecordOf<{ key: string; label: string }>>
  updateRelationship: (arg: string) => void
}

type IAttributes = Map<
  string,
  RecordOf<{
    modified: {
      user: string
      date: string
    }
    note: string
    status: boolean
  }>
>

export const RelationshipAttributes: FunctionComponent<Props> = (props) => {
  const [attributes, setAttributes] = useState<IAttributes>(props.attributes)
  const [editMode, setEditMode] = useState<boolean>(false)

  useEffect(() => {
    if (props.attributes) {
      setEditMode(false)
    }
  }, [props.attributes])

  const handleStatusChange = (e) => {
    const { currentUserId } = props

    const attributeKey = e.target.name
    const newStatus = !!e.target.checked
    const newNote = newStatus ? attributes.getIn([attributeKey, 'note']) : ''

    setAttributes(
      attributes.mergeIn([attributeKey], {
        status: newStatus,
        note: newNote,
        modified: {
          user: currentUserId,
          date: new Date().toISOString(),
        },
      })
    )
  }

  const handleNoteChange = (e) => {
    const { currentUserId } = props
    const attributeKey = e.target.name
    setAttributes(
      attributes.mergeIn([attributeKey], {
        note: e.target.value,
        modified: {
          user: currentUserId,
          date: new Date().toISOString(),
        },
      })
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    props.updateRelationship(
      fromJS({
        attributes:
          attributes &&
          attributes.map((attributes) =>
            attributes.deleteIn(['modified', 'userInfo'])
          ),
      })
    )
  }

  const handleEdit = () => {
    setEditMode(true)
  }

  const handleCancel = () => {
    setEditMode(false)
    setAttributes(props.attributes)
  }

  const renderRelationshipAttributes = () => {
    const { orderedRelationshipAttributes } = props
    return (
      <>
        {orderedRelationshipAttributes &&
          orderedRelationshipAttributes.map((attribute) =>
            renderAttribute(attribute.get('key'), attribute.get('label'))
          )}
      </>
    )
  }

  const renderAttributeInput = (attributeKey, attributeLabel) => {
    const attributeStatus = attributes.getIn([attributeKey, 'status'])
    const attributeNote = attributes.getIn([attributeKey, 'note'])

    return (
      <div key={attributeKey} className='mt3'>
        <input
          id={attributeKey}
          name={attributeKey}
          checked={!!attributeStatus}
          onChange={handleStatusChange}
          type='checkbox'
        />
        <Label
          className='lh-copy f7 fw4 mid-gray ma0 dib ml2'
          htmlFor={attributeKey}
        >
          {icons[attributeKey] && (
            <img
              src={icons[attributeKey]}
              alt={attributeLabel}
              className='h1 w1 dib v-mid mr1'
            />
          )}
          {attributeLabel}
        </Label>

        {attributeStatus && (
          <div className='ml3 pl2'>
            <Label>
              <FormattedMessage
                id='RelationshipAttributes.WhyIsThisCompanyAttribute'
                defaultMessage='Why is this company {attributeLabel}?'
                values={{ attributeLabel }}
              />
            </Label>
            <TextArea
              name={attributeKey}
              value={attributeNote}
              onChange={handleNoteChange}
            />
          </div>
        )}
      </div>
    )
  }

  const renderAttribute = (attributeKey, attributeLabel) => {
    const { attributes } = props
    const modifiedBy = attributes.getIn([attributeKey, 'modified', 'userInfo'])
    const modifiedDate = attributes.getIn([attributeKey, 'modified', 'date'])

    const firstName = modifiedBy && modifiedBy.get('firstName')
    const lastName = modifiedBy && modifiedBy.get('lastName')
    const profilePictureUrl = modifiedBy && modifiedBy.get('profilePictureUrl')
    const userId = modifiedBy && modifiedBy.get('id')
    const status = attributes.getIn([attributeKey, 'status'])
    const note = attributes.getIn([attributeKey, 'note'])

    return (
      status && (
        <div key={attributeKey} className={styles.wrapper}>
          <div className={styles.contentWrapper}>
            <div className={styles.iconLabelWrapper}>
              {icons[attributeKey] && (
                <img
                  src={icons[attributeKey]}
                  alt={attributeLabel}
                  className={styles.icon}
                />
              )}
              {attributeLabel}
            </div>

            {modifiedBy && (
              <UserComment
                date={modifiedDate}
                profilePictureUrl={profilePictureUrl}
                userProfileUrl={parsePath(buyerPaths.userprofile, { userId })}
                firstName={firstName}
                lastName={lastName}
                comment={note}
                editMode={false}
                isPrivate={false}
                enablePublicPrivate={false}
                customWrapper='dt w-100 pv3'
              />
            )}
          </div>
        </div>
      )
    )
  }
  const {
    canEditRelationshipAttributes,
    hasAttributes,
    orderedRelationshipAttributes,
  } = props

  return canEditRelationshipAttributes || hasAttributes ? (
    <PageSection
      title={
        <FormattedMessage
          id='RelationshipAttributes.CorporateRelationship'
          defaultMessage='Corporate Relationship'
        />
      }
    >
      {canEditRelationshipAttributes && (
        <FormattedMessage
          id='RelationshipAttributes.AddACorporateRelationship'
          defaultMessage='Add a corporate relationship'
        >
          {(message) => (
            <EditForm
              title={
                <FormattedMessage
                  id='RelationshipAttributes.SelectAllThatApply'
                  defaultMessage='Select all that apply'
                />
              }
              showReadModeContent={hasAttributes}
              placeholder={message}
              readModeContent={renderRelationshipAttributes()}
              onSubmit={handleSubmit}
              onEdit={handleEdit}
              onCancel={handleCancel}
              editMode={editMode}
              canEditSection={true}
            >
              {orderedRelationshipAttributes.map((attribute) =>
                renderAttributeInput(
                  attribute.get('key'),
                  attribute.get('label')
                )
              )}
            </EditForm>
          )}
        </FormattedMessage>
      )}

      {!canEditRelationshipAttributes && renderRelationshipAttributes()}
    </PageSection>
  ) : null
}

export default connect(
  (state: RootState) => {
    const attributes = profileSelectors.getRelationshipAttributes(state)
    const currentUserId = sessionSelectors.getUserId(state)
    const currentUser = usersSelectors.getById(state, currentUserId)
    return {
      attributes,
      hasAttributes: attributes.size > 0,
      canEditRelationshipAttributes: sessionSelectors.userHasRole(
        state,
        'buyerDataAdmin'
      ),
      orderedRelationshipAttributes:
        settingsSelectors.getRelationshipAttributesList(state),
      currentUserId,
      currentUser,
    }
  },
  {
    updateRelationship,
  }
)(RelationshipAttributes)
